import { Box, Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import backgroundImage from 'targets/web/assets/images/Header.png';

import { GameQRCode, Header } from '../../common/components';
import { WaitingRoomInfoCard } from '../components';
import { useGolfCourseContext } from '../hooks/useGolfCourseContext';

import Step1Image from 'targets/web/assets/images/waiting-room/Step1.png';
import Step2Image from 'targets/web/assets/images/waiting-room/Step2.png';
import Step3Image from 'targets/web/assets/images/waiting-room/Step3.png';

const images = [Step1Image, Step2Image, Step3Image];

const WaitingRoomHeader: FC<{ title: string }> = ({ title }) => {
  const t = useTranslationPrefix('waitingRoom.header');

  return (
    <Stack
      sx={{
        flex: 1,
        gap: 6,
        padding: 10,
        paddingBlockEnd: 0,

        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `
        linear-gradient(
          180deg, rgba(0, 0, 0, 0) 62.5%,
          rgba(0, 0, 0, 0.54) 70.75%,
          #000000 100%
        ),
        url(${backgroundImage})
      `,
        maxHeight: 380,

        textAlign: 'center',
        color: 'typo.white.primary',

        [`@media (max-height: 1079px)`]: {
          gap: 3,
          padding: 5,
        },
      }}>
      <Header title={title ?? ''} />

      <Box>
        <Typography variant="displayMedium">{t('title')}</Typography>
        {/* TODO: Should be dynamic */}
        <Typography variant="displayMedium">{t('subTitle')}</Typography>
      </Box>
    </Stack>
  );
};

export const WaitingRoom: FC = () => {
  const t = useTranslationPrefix('waitingRoom');
  const { title } = useGolfCourseContext();

  const cards = useMemo(
    () =>
      images.map((image, i) => ({
        number: i + 1,
        title: t(`cards.${i + 1}.title`),
        subtitle: t(`cards.${i + 1}.subTitle`),
        image,
      })),
    [t],
  );

  return (
    <Stack sx={{ minHeight: '100vh', backgroundColor: 'background.default' }}>
      <WaitingRoomHeader title={title ?? ''} />

      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          flex: 1,
          alignItems: 'stretch',
          gap: 8,
          paddingInline: 10,
          backgroundColor: 'opacity.80',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,

          [`@media (max-height: 1079px)`]: {
            paddingInline: 8,
          },
        }}>
        {cards.map((card, i) => (
          <WaitingRoomInfoCard
            key={i}
            number={card.number}
            title={card.title}
            subtitle={card.subtitle}
            image={card.image}
          />
        ))}
      </Stack>

      <Stack sx={{ alignItems: 'center', padding: 6 }}>
        <GameQRCode />
      </Stack>
    </Stack>
  );
};
