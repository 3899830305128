import { createTheme } from '@mui/material/styles';

import { breakpoints } from './breakpoints';
import { components } from './components';
import { palette } from './palette';
import { spacing } from './spacing';
import { typography } from './typography';

export const theme = createTheme({
  breakpoints,
  spacing,
  typography,
  components,
  palette,
});
