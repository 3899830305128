import { Palette, Stack, StackProps, styled, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

import AppIcon from 'targets/web/assets/icons/appIcon.svg?react';

type Color = 'success' | 'danger' | 'neutral';
interface ScorePillProps {
  color?: Color;
  children: ReactNode;
  omitIcon?: boolean;
  omitShadow?: boolean;
}

const colorMap: Record<Color, keyof Palette> = {
  success: 'green',
  danger: 'red',
  neutral: 'grey',
};

const Container = styled(Stack, { shouldForwardProp: (prop) => prop !== 'color' })<
  Pick<ScorePillProps, 'color'>
>(({ theme, color }) => ({
  gap: theme.spacing(3),
  paddingBlock: theme.spacing(4),
  paddingInline: theme.spacing(10),
  borderRadius: theme.spacing(21),

  backgroundColor: theme.palette[colorMap[color ?? 'neutral']].main,
  alignItems: 'center',
  textTransform: 'uppercase',
  color: theme.palette.typo.white.primary,
}));

export const ScorePill: FC<ScorePillProps & StackProps> = ({
  color,
  children,
  omitIcon,
  ...props
}) => (
  <Container {...props} direction="row" color={color}>
    {!omitIcon && <AppIcon style={{ width: 84, height: 84 }} />}

    {typeof children === 'string' ? (
      <Typography variant="displayLarge">{children}</Typography>
    ) : (
      children
    )}
  </Container>
);
