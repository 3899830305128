import { ThemeOptions } from '@mui/material';

export const components: ThemeOptions['components'] = {
  MuiTypography: {
    defaultProps: {
      variant: 'bodyLarge',
      variantMapping: {
        displayLarge: 'h1',
        displayMedium: 'h2',
        displaySmall: 'h3',

        headlineLarge: 'h4',
        headlineMedium: 'h5',
        headlineSmall: 'h6',

        bodyLarge: 'p',
        bodyMedium: 'p',
        bodySmall: 'p',

        labelLarge: 'label',
        labelMedium: 'label',
        labelSmall: 'label',

        titleLarge: 'h6',
        titleMedium: 'h6',
        titleSmall: 'h6',

        inherit: 'p',
      },
    },
  },
};
