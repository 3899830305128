import { injectable } from 'inversify';
import { Navigate, Route } from 'react-router';

import IWebModule from 'targets/web/types/IWebModule';
import { DisplayPageLayout } from './components';
import { DisplayNavigationRoutes } from './navigation/DisplayNavigationRoutes';
import { ContestInProgress } from './views/ContestInProgress.view';
import { ResultsScreen } from './views/Results.view';
import { WaitingRoom } from './views/WaitingRoom.view';

@injectable()
class DisplayModule implements IWebModule {
  getRoutes(): JSX.Element {
    return (
      <>
        <Route element={<DisplayPageLayout />} path="/:locationId/:bayId">
          <Route path={DisplayNavigationRoutes.WaitingRoom} element={<WaitingRoom />} />
          <Route path={DisplayNavigationRoutes.ContestInProgress} element={<ContestInProgress />} />
          <Route path={DisplayNavigationRoutes.Results} element={<ResultsScreen />} />
          <Route index element={<Navigate to={DisplayNavigationRoutes.WaitingRoom} replace />} />
        </Route>
        <Route path="*/*" element={<Navigate to="/404" />} />
      </>
    );
  }
}

export default DisplayModule;
