import { Stack, Typography } from '@mui/material';

import { ContentContainer, PageLayout } from '../components';

export const PageNotFound = () => (
  <PageLayout>
    <ContentContainer
      sx={{
        color: 'typo.white.primary',
      }}>
      <Stack height={1} gap={6} alignItems="center" justifyContent="center">
        <Typography variant="displayLarge">404</Typography>

        <Stack gap={4} textAlign="center">
          <Typography variant="displayMedium">
            Oops. The page you were looking for does not exist.
          </Typography>

          <Typography variant="displaySmall">
            You may have mistyped the address or the page may have moved.
          </Typography>
        </Stack>
      </Stack>
    </ContentContainer>
  </PageLayout>
);
