import { Stack } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { FC } from 'react';
import { useParams } from 'react-router';

interface QRCodeContent {
  bayName: string;
  locationName: string;
}

const QRCode: FC<QRCodeContent> = (codeContent) => (
  <Stack
    sx={{
      width: 160,
      height: 160,
      backgroundColor: 'typo.white.primary',
      alignItems: 'center',
      borderRadius: 6,
      padding: 5,
    }}>
    <QRCodeSVG width={'120px'} height={'120px'} value={JSON.stringify(codeContent)} />
  </Stack>
);

export const GameQRCode: FC = () => {
  const { locationId, bayId } = useParams();

  if (locationId && bayId) {
    return <QRCode bayName={bayId} locationName={locationId} />;
  }

  return null;
};
