import { useAllInjections } from 'inversify-react';
import React, { Fragment } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import IWebModule, { WebModule } from 'targets/web/types/IWebModule';

import { PageNotFound } from '../modules/common/views/PageNotFound';

const Router: React.FC = () => {
  const modules = useAllInjections<IWebModule>(WebModule);

  return (
    <BrowserRouter>
      <Routes>
        {modules.map((m, idx) => (
          <Fragment key={idx}>{m.getRoutes()}</Fragment>
        ))}
        <Route path="404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
