import { GolfCourseConfig } from 'common/infrastructure/modules/game';
import { GameEvent } from 'common/infrastructure/modules/game/graphql';
import { useOutletContext } from 'react-router';

export const useGolfCourseContext = () => {
  const context = useOutletContext<{
    lastGameEvent: GameEvent | null;
    gameConfigs: GolfCourseConfig[];
    title?: string;
  }>();

  return context;
};
