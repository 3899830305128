import { Box, CircularProgress, CircularProgressProps, Stack, Typography } from '@mui/material';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { DisplayNavigationRoutes } from '../../navigation/DisplayNavigationRoutes';

const CircularProgressWithLabel = ({
  children,
  ...props
}: CircularProgressProps & { children?: ReactNode }) => {
  return (
    <Stack sx={{ alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
      <CircularProgress variant="determinate" {...props} />
      <Stack
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {children}
      </Stack>
    </Stack>
  );
};

export const normalize =
  (MIN = 0, MAX = 100) =>
  (value: number) =>
    ((value - MIN) * 100) / (MAX - MIN);

const TIMEOUT = 10;
export const CountDown: FC<{ startValue?: number; min?: number; max?: number }> = ({
  startValue = TIMEOUT,
  min = 0,
  max = 100,
}) => {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(startValue);

  const normalizeFn = useMemo(() => normalize(min, max), [min, max]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress === 0 ? 0 : prevProgress - 1));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (progress > 0) {
      return;
    }

    setTimeout(() => {
      navigate(`../${DisplayNavigationRoutes.WaitingRoom}`, { replace: true });
    }, 1000);
  }, [progress, navigate]);

  return (
    <Box sx={{ position: 'absolute', right: 40, bottom: 40, color: 'typo.white.primary' }}>
      <CircularProgressWithLabel
        color="inherit"
        value={normalizeFn(progress)}
        size={120}
        thickness={5}>
        <Typography variant="displayLarge">{progress}</Typography>
      </CircularProgressWithLabel>
    </Box>
  );
};
