import { TypographyOptions } from '@mui/material/styles/createTypography';

export const WombyFont = 'Womby, sans-serif';
export const NunitoFont = 'Nunito, sans-serif';

export const RebellionSquadFont = 'RebellionSquad, sans-serif';
export const TitanOneFont = 'Titan One, sans-serif';
export const SpicyRiceFont = 'Spicy Rice, sans-serif';
export const RanchersFont = 'Ranchers, sans-serif';
export const ProtestGuerrillaFont = 'Protest Guerrilla, sans-serif';
export const RubikGlitchFont = 'Rubik Glitch, sans-serif';
export const RubikDoodleShadowFont = 'Rubik Doodle Shadow, sans-serif';


const BODY_FONT_WEIGHT_REGULAR = 400;
const BODY_FONT_WEIGHT_MEDIUM = 500;
const BODY_FONT_WEIGHT_BOLD = 700;

const common = {
  fontFamily: NunitoFont,
  lineHeight: 1.25,
  letterSpacing: '-0.0005em',
};

export const typography: TypographyOptions = {
  fontFamily: NunitoFont,
  fontWeightLight: undefined,
  fontWeightRegular: BODY_FONT_WEIGHT_REGULAR,
  fontWeightMedium: BODY_FONT_WEIGHT_MEDIUM,
  fontWeightBold: BODY_FONT_WEIGHT_BOLD,

  h1: undefined,
  h2: undefined,
  h3: undefined,
  h4: undefined,
  h5: undefined,
  h6: undefined,
  body1: undefined,
  body2: undefined,
  caption: undefined,

  displayLarge: {
    ...common,
    fontSize: 84,
    fontFamily: WombyFont,
    fontWeight: BODY_FONT_WEIGHT_REGULAR,
  },
  displayMedium: {
    ...common,
    fontSize: 60,
    fontFamily: WombyFont,
    fontWeight: BODY_FONT_WEIGHT_REGULAR,
  },
  displaySmall: {
    ...common,
    fontSize: 40,
    fontFamily: WombyFont,
    fontWeight: BODY_FONT_WEIGHT_REGULAR,
  },
  headlineLarge: {
    ...common,
    fontSize: 36,
    fontFamily: WombyFont,
    fontWeight: BODY_FONT_WEIGHT_REGULAR,
  },
  headlineMedium: {
    ...common,
    fontSize: 30,
    fontFamily: WombyFont,
    fontWeight: BODY_FONT_WEIGHT_REGULAR,
  },
  headlineSmall: {
    ...common,
    fontSize: 24,
    fontFamily: WombyFont,
    fontWeight: BODY_FONT_WEIGHT_REGULAR,
  },

  bodyLarge: {
    ...common,
    fontSize: 16,
  },
  bodyMedium: {
    ...common,
    fontSize: 14,
  },
  bodySmall: {
    ...common,
    fontSize: 12,
  },

  labelLarge: {
    ...common,
    fontSize: 14,
  },
  labelMedium: {
    ...common,
    fontSize: 12,
  },
  labelSmall: {
    ...common,
    fontSize: 11,
  },

  titleLarge: {
    ...common,
    fontSize: 22,
  },
  titleMedium: {
    ...common,
    fontSize: 16,
  },
  titleSmall: {
    ...common,
    fontSize: 14,
  },
};
