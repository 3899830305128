import { useQuery } from '@tanstack/react-query';

import { useHookInjection } from 'domain/hooks';
import { GolfCourseRepository } from 'domain/repositories';

export const CourseConfigKey = 'CourseConfig';

export const useCaseGetGolfCoursesConfig = (location?: string) => {
  const { fetchGolfCourseConfig } = useHookInjection<GolfCourseRepository>(GolfCourseRepository);

  const { data, error, isLoading } = useQuery({
    queryKey: [CourseConfigKey, { location }] as const,
    enabled: !!location,
    queryFn: ({ queryKey }) => fetchGolfCourseConfig({ location: queryKey[1].location ?? '' }),
  });

  return { data: data ?? [], error, isLoading };
};
