import { CssBaseline, ThemeProvider } from '@mui/material';
import { Provider } from 'inversify-react';

import { InfrastructureProvider } from 'infrastructure/contexts/InfrastructureProvider';

import container from './container';
import Router from './routes/Router';
import { theme } from './theme/theme';

function App() {
  return (
    <Provider container={container}>
      <InfrastructureProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router />
        </ThemeProvider>
      </InfrastructureProvider>
    </Provider>
  );
}

export default App;
