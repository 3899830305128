import { ContainerModule } from 'inversify';
import 'reflect-metadata';

import { GolfCourseRepository } from 'domain/repositories/IGolfCourseRepository';

import { useGolfCourseRepository } from './providers';

export const infrastructureModule = new ContainerModule((bind) => {
  bind(GolfCourseRepository).toFunction(useGolfCourseRepository);
});
