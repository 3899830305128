import { Box, Stack, Typography } from '@mui/material';
import { NunitoFont } from 'targets/web/theme/typography';

interface InfoCardProps {
  number: number;
  title: string;
  subtitle: string;
  image: string;
}

export const WaitingRoomInfoCard = ({ number, title, subtitle, image }: InfoCardProps) => (
  <Stack
    sx={{
      flex: 1,
      gap: 6,
      padding: 8,
      borderRadius: 2,
      backgroundColor: 'background.paper',
      textWrap: 'pretty',
    }}>
    <Box
      sx={{
        height: 180,
        backgroundColor: 'background.neutral',
        borderRadius: 2,
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    />

    <Stack
      gap={6}
      direction={{ xs: 'row', md: 'column' }}
      sx={{
        [`@media (max-height: 1023px)`]: {
          alignItems: 'center',
          flexDirection: 'row',
        },
      }}>
      <Stack
        sx={{
          flex: 'none',
          width: 84,
          height: 84,
          backgroundColor: 'background.neutral',
          borderRadius: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Typography variant="displaySmall">{number}</Typography>
      </Stack>

      <Typography variant="headlineMedium" sx={{ textTransform: 'uppercase' }}>
        {title}
      </Typography>
    </Stack>

    <Typography
      variant="headlineSmall"
      sx={{ fontFamily: NunitoFont, color: 'typo.white.secondary' }}>
      {subtitle}
    </Typography>
  </Stack>
);
