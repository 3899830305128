import { Stack, Typography } from '@mui/material';
import { ShotContentChallengeState } from 'common/infrastructure/modules/game';
import { FC } from 'react';
import AppIcon from 'targets/web/assets/icons/appIcon.svg?react';
import { ChallengeRow } from 'targets/web/modules/display/interfaces/ChallengeRow';

export const ChallengeChip: FC<
  Pick<ChallengeRow, 'chipColor'> & { status: ShotContentChallengeState; points: number }
> = ({ chipColor, status, points }) => (
  <Stack
    direction="row"
    sx={{
      gap: 1,
      paddingBlock: 2,
      paddingInline: 3,
      alignItems: 'center',

      borderRadius: 1000,
      backgroundColor: chipColor,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'border.outline',
      boxShadow: `0px 8px 16px 0px rgba(0, 0, 0, 0.32)`,
      backdropFilter: 'blur(24px)',

      textTransform: 'uppercase',
    }}>
    <AppIcon style={{ width: 32, height: 32 }} />

    <Typography variant="headlineSmall">
      {status !== ShotContentChallengeState.COMPLETED ? `WIN ${points} pts` : `${points} pts won`}
    </Typography>
  </Stack>
);
