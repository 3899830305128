import { BreakpointsOptions, createBreakpoints } from '@mui/system';

declare module '@mui/system' {
  interface BreakpointOverrides {
    fullHD: true;
    quadHD: true;
    ultraHD: true;
  }
}

export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 650,
    md: 1024,
    lg: 1200,
    xl: 1536,
    fullHD: 1920,
    quadHD: 2048,
    ultraHD: 3840,
  },
};

export const themeBreakpoints = createBreakpoints(breakpoints);

export default breakpoints;
