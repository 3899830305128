import TrophyIcon from '@mui/icons-material/EmojiEventsOutlined';
import { Stack, styled, Typography } from '@mui/material';
import { ChallengeConfig, ShotContentChallengeState } from 'common/infrastructure/modules/game';
import { FC, useMemo, useRef } from 'react';
import Confetti from 'react-confetti';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { ChallengeChip } from 'targets/web/modules/display/components/ChallengeChip/ChallengeChip';
import { challengesSettings } from 'targets/web/modules/display/consts/ChallengesSettings';
import { useResizeObserver } from 'usehooks-ts';

import { ChallengeRow } from '../../interfaces/ChallengeRow';

type RowContainerProps = ChallengeRow & { state: ShotContentChallengeState };
const RowContainer = styled(Stack, {
  shouldForwardProp: (prop) => !['backgroundImage', 'style', 'state'].includes(prop as string),
})<Pick<RowContainerProps, 'backgroundImage' | 'style' | 'state'>>(
  ({ theme, style, backgroundImage, state }) => ({
    position: 'relative',
    gap: theme.spacing(4),

    flex: 1,
    borderRadius: theme.spacing(2),
    paddingInline: theme.spacing(4),
    paddingBlock: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: theme.palette.shadow.card,
    color: theme.palette.typo.white.primary,
    background: `${style.background}, url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    opacity:
      state === ShotContentChallengeState.IN_PROGRESS ||
      state === ShotContentChallengeState.COMPLETED
        ? 1
        : 0.4,
  }),
);

export const ChallengeRowComponent: FC<ChallengeConfig & { state: ShotContentChallengeState }> = (
  challenge,
) => {
  const t = useTranslationPrefix('contestInProgress.challengeRow');
  const challengeConfig = useMemo(() => challengesSettings[challenge.subtype], [challenge.subtype]);

  const ref = useRef<HTMLDivElement>(null);
  const confettiRef = useRef<HTMLCanvasElement>(null);
  const { width = 0, height = 0 } = useResizeObserver({
    ref,
    box: 'border-box',
  });

  const Icon = challengeConfig.Icon;

  const challengeStatusLabel = useMemo(() => {
    switch (challenge.state) {
      case ShotContentChallengeState.COMPLETED:
      case ShotContentChallengeState.IN_PROGRESS:
        return t(challenge.state);
      case ShotContentChallengeState.NOT_STARTED:
      case ShotContentChallengeState.FAILED:
        return '';
      default:
        console.error('Unknown challenge status:', challenge.state);
        return '';
    }
  }, [t, challenge.state]);

  const isCompleted = challenge.state === ShotContentChallengeState.COMPLETED;

  return (
    <RowContainer
      ref={ref}
      direction="row"
      state={challenge.state}
      style={challengeConfig.style}
      backgroundImage={challengeConfig.backgroundImage}>
      {width && height && isCompleted && (
        <Confetti ref={confettiRef} width={width} height={height} />
      )}

      <Stack direction="row" sx={{ alignItems: 'center', gap: 4 }}>
        <Icon
          style={{
            opacity: challenge.state !== ShotContentChallengeState.COMPLETED ? 0.4 : 1,
          }}
        />

        <Typography
          variant="displaySmall"
          sx={{ textTransform: 'none', fontFamily: challengeConfig.style.fontFamily }}>
          {challengeConfig.title}
        </Typography>
      </Stack>

      <Stack direction="row" sx={{ gap: 4, alignItems: 'center' }}>
        <Stack direction="row" sx={{ gap: 2, alignItems: 'center' }}>
          {challenge.state == ShotContentChallengeState.COMPLETED && (
            <TrophyIcon sx={{ fontSize: 40 }} />
          )}
          <Typography
            variant="headlineLarge"
            sx={{
              color: 'typo.white.primary',
            }}>
            {challengeStatusLabel}
          </Typography>
        </Stack>

        <ChallengeChip
          status={challenge.state}
          points={challenge.points}
          chipColor={challengeConfig.chipColor}
        />
      </Stack>
    </RowContainer>
  );
};
