import { Container } from 'inversify';

import { infrastructureModule } from 'infrastructure/infrastructure.module';

import DisplayModule from './modules/display/module';
import IWebModule, { WebModule } from './types/IWebModule';

const container = new Container();

container.load(infrastructureModule);
container.bind<IWebModule>(WebModule).to(DisplayModule);

export default container;
