import { PaletteOptions } from '@mui/material';

export const palette: PaletteOptions = {
  primary: {
    main: '#F50057',
    dark: '#AB003C',
    light: '#F73378',
    contrastText: '#FFFFFF',
  },

  text: {
    primary: '#FFFFFF',
    secondary: '#E5E5E5',
  },
  typo: {
    white: {
      primary: '#FFFFFF',
      secondary: '#E5E5E5',
    },
    black: {
      primary: '#000000',
      secondary: '#262626',
    },
  },

  orange: {
    main: '#CE4104',
  },
  purple: {
    light: '#6D34A7',
    main: '#4923E2',
    dark: '#740174',
  },
  blue: {
    main: '#0070D0',
    dark: '#3451A7',
  },
  green: {
    light: '#168709',
    main: '#15803D',
    dark: '#03665C',
  },
  grey: {
    // @ts-expect-error ts(2353) This property exists in the type 'PaletteColorOptions' but is has the type 'Color'
    main: '#525252',
  },
  red: {
    main: '#CF1743',
  },

  background: {
    default: '#000000',
    paper: '#171717',
    neutral: '#262626',
  },
  border: {
    outline: 'rgba(255, 255, 255, 0.1)',
  },
  opacity: {
    50: 'rgba(0, 0, 0, 0.5)',
    80: 'rgba(0, 0, 0, 0.8)',
  },
  shadow: {
    card: '0px 24px 48px 0px rgba(60, 213, 199, 0.2)',
    green: '0px 16px 64px 0px rgba(21, 128, 61, 0.48)',
    gray: '0px 16px 64px 0px rgba(82, 82, 82, 0.48)',
    red: '31.35px 7.84px 0px rgba(226, 23, 72, 0.32)',
  },
};
