import { Stack, Typography } from '@mui/material';
import { GameEventType } from 'common/infrastructure/modules/game';
import { FC } from 'react';

import UserLostImage from 'targets/web/assets/images/resultsLose.svg?react';
import UserWonImage from 'targets/web/assets/images/resultsWon.svg?react';

import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { RubikGlitchFont, TitanOneFont } from 'targets/web/theme/typography';
import { Header, PageLayout, ScorePill } from '../../common/components';
import { CountDown } from '../components';
import { ResultsViewNavigationTimeout } from '../hooks/useDispatchDisplayRoutes';
import { useGolfCourseContext } from '../hooks/useGolfCourseContext';

export const ResultsScreen: FC = () => {
  const t = useTranslationPrefix('results');
  const { lastGameEvent, title } = useGolfCourseContext();

  const didUserWin =
    lastGameEvent?.type === GameEventType.END && lastGameEvent.players[0].challengesWon > 0;

  if (lastGameEvent?.type !== GameEventType.END) {
    return null;
  }

  return (
    <PageLayout>
      <Header title={title ?? ''} user={lastGameEvent?.players[0].name} />
      <Stack direction="row" height={1} gap={10} sx={{ color: 'typo.white.primary' }}>
        <Stack
          flex={'2'}
          sx={{
            gap: 8,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 2,
          }}>
          {didUserWin ? <UserWonImage /> : <UserLostImage />}

          <Stack direction="row" gap={4} alignItems="center">
            <ScorePill color={'danger'} omitIcon>
              <Typography
                variant="displayLarge"
                sx={{
                  fontFamily: RubikGlitchFont,
                  textTransform: 'uppercase',
                }}>
                {didUserWin ? lastGameEvent.players[0].challengesWon : 0}
              </Typography>
            </ScorePill>

            <Typography
              variant="displayLarge"
              sx={{
                fontFamily: RubikGlitchFont,
                textTransform: 'uppercase',
              }}>
              {t('challenges')}
              {didUserWin ? '!' : ''}
            </Typography>
          </Stack>

          <Stack gap={6} alignItems="center">
            <Typography
              variant="displayMedium"
              sx={{
                fontFamily: TitanOneFont,
                textTransform: 'uppercase',
              }}>
              {t('subTitle')}
            </Typography>

            <ScorePill
              color={didUserWin ? 'success' : 'neutral'}
              sx={{
                boxShadow: (theme) =>
                  didUserWin ? theme.palette.shadow.green : theme.palette.shadow.gray,
              }}>
              {['+', didUserWin ? lastGameEvent.players[0].wager : 0].join('')}
            </ScorePill>
          </Stack>
        </Stack>
      </Stack>

      <CountDown startValue={ResultsViewNavigationTimeout} min={0} max={9} />
    </PageLayout>
  );
};
