import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import AppIcon from 'targets/web/assets/icons/appIcon.svg?react';

interface HeaderProps {
  title: string;
  user?: string;
}

const Bay: FC<{ title: string }> = ({ title }) => (
  <Stack
    direction="row"
    sx={{
      gap: 3,
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    }}>
    <AppIcon style={{ flex: 'none', width: '70px', height: '70px' }} />

    <Typography
      variant="displayMedium"
      sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
      {title}
    </Typography>
  </Stack>
);

const User: FC<{ name: string }> = ({ name }) => (
  <Typography
    variant="displayMedium"
    sx={{
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }}>
    {name}
  </Typography>
);

export const Header: FC<HeaderProps> = (props) => {
  return (
    <Stack
      direction="row"
      sx={{
        gap: 6,
        justifyContent: props.user ? 'space-between' : 'center',

        textTransform: 'uppercase',
        color: 'typo.white.primary',
      }}>
      <Bay title={props.title} />

      {props.user && <User name={props.user} />}
    </Stack>
  );
};
