import { useCallback } from 'react';

import { GolfCourseRepository } from 'domain/repositories/IGolfCourseRepository';

import { httpClient } from '../common/httpClient';

export const ContestRoutes = {
  getGolfCoursesConfig: (location: string) => {
    const searchParams = new URLSearchParams({ location });
    return `game-engine/game-engine/golf-courses?${searchParams.toString()}`;
  },
};

export const useGolfCourseRepository: GolfCourseRepository = () => {
  const fetchGolfCourseConfig: ReturnType<GolfCourseRepository>['fetchGolfCourseConfig'] =
    useCallback(
      (params) =>
        httpClient
          .get(ContestRoutes.getGolfCoursesConfig(params.location))
          .then((response) => response.data),
      [],
    );

  return {
    fetchGolfCourseConfig,
  };
};
