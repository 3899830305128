import { ChallengeSubtype } from 'common/infrastructure/modules/game';

import CrownIcon from 'targets/web/assets/icons/crown-blue.svg?react';
import DiamondBlueIcon from 'targets/web/assets/icons/diamond-blue.svg?react';
import DiamondGreenIcon from 'targets/web/assets/icons/diamond-green.svg?react';
import DiamondOrangeIcon from 'targets/web/assets/icons/diamond-orange.svg?react';
import DiamondVioletIcon from 'targets/web/assets/icons/diamond-violet.svg?react';
import StarIcon from 'targets/web/assets/icons/star.svg?react';
import FairwayBackground from 'targets/web/assets/images/challenges/fairway_in_n_shot.png';
import GreenBackground from 'targets/web/assets/images/challenges/green_in_n_shots.png';
import LessThanBackground from 'targets/web/assets/images/challenges/less_than_n_ft.png';
import NoBunkersBackground from 'targets/web/assets/images/challenges/no_bunkers.png';
import NoRoughBackground from 'targets/web/assets/images/challenges/no_rough.png';
import NoWaterBackground from 'targets/web/assets/images/challenges/no_water.png';
import TeeShotBackground from 'targets/web/assets/images/challenges/tee_shot_over_220_yards_on_fairway.png';

import { ChallengeRow } from 'targets/web/modules/display/interfaces/ChallengeRow';
import {
  ProtestGuerrillaFont,
  RanchersFont,
  RebellionSquadFont,
  RubikDoodleShadowFont,
  RubikGlitchFont,
  SpicyRiceFont,
  TitanOneFont,
} from 'targets/web/theme/typography';

export const challengesSettings = {
  [ChallengeSubtype.MakeFairwayIn1]: {
    Icon: DiamondGreenIcon,
    title: 'Fairway in 1 shot',
    chipColor: 'green.dark',
    backgroundImage: FairwayBackground,
    style: {
      fontFamily: RebellionSquadFont,
      background: 'linear-gradient(101.39deg, rgba(0, 0, 0, 0.65) 0%, rgba(64, 231, 215, .8) 100%)',
    },
  },
  [ChallengeSubtype.MakeTheGreenIn1]: {
    title: 'Green in 1 shot',
    Icon: CrownIcon,
    chipColor: 'purple.main',
    backgroundImage: GreenBackground,
    style: {
      fontFamily: TitanOneFont,
      background:
        'linear-gradient(108.21deg, rgba(118, 83, 255, 0.65) 0%, rgba(73, 35, 226, .8) 100%)',
    },
  },
  [ChallengeSubtype.MakeTheGreenIn2]: {
    title: 'Green in 2 shots',
    Icon: DiamondOrangeIcon,
    chipColor: 'orange.main',
    backgroundImage: GreenBackground,
    style: {
      fontFamily: SpicyRiceFont,
      background: 'linear-gradient(108.21deg, rgba(193, 65, 9, .65) 0%, rgba(206, 65, 4, .8) 100%)',
    },
  },
  [ChallengeSubtype.MakeTheGreenIn3]: {
    title: 'Green in 3 shots',
    Icon: CrownIcon,
    chipColor: 'blue.main',
    backgroundImage: GreenBackground,
    style: {
      fontFamily: SpicyRiceFont,
      background:
        'linear-gradient(108.21deg, rgba(31, 112, 238, .65) 0%, rgba(14, 40, 80, .8) 100%)',
    },
  },
  [ChallengeSubtype.LessThan10FtFromPinIn1]: {
    title: 'Less than 10ft from pin in 1 shot',
    Icon: DiamondBlueIcon,
    chipColor: 'blue.main',
    backgroundImage: LessThanBackground,
    style: {
      fontFamily: RanchersFont,
      background:
        'linear-gradient(108.21deg, rgba(31, 112, 238, .65) 0%, rgba(14, 40, 80, .8) 100%)',
    },
  },
  [ChallengeSubtype.LessThan10FtFromPinIn2]: {
    title: 'Less than 10ft from pin in 2 shots',
    Icon: DiamondBlueIcon,
    chipColor: 'blue.main',
    backgroundImage: LessThanBackground,
    style: {
      fontFamily: RanchersFont,
      background:
        'linear-gradient(108.21deg, rgba(31, 112, 238, .65) 0%, rgba(14, 40, 80, .8) 100%)',
    },
  },
  [ChallengeSubtype.LessThan10FtFromPinIn3]: {
    title: 'Less than 10ft from pin in 3 shots',
    Icon: DiamondBlueIcon,
    chipColor: 'blue.main',
    backgroundImage: LessThanBackground,
    style: {
      fontFamily: RanchersFont,
      background:
        'linear-gradient(108.21deg, rgba(31, 112, 238, .65) 0%, rgba(14, 40, 80, .8) 100%)',
    },
  },
  [ChallengeSubtype.TeeShotOver220YardsOnFairway]: {
    Icon: DiamondVioletIcon,
    title: 'Tee shot over 220 yards on fairway',
    chipColor: 'purple.dark',
    backgroundImage: TeeShotBackground,
    style: {
      fontFamily: ProtestGuerrillaFont,
      background: `linear-gradient(108.21deg, rgba(116, 1, 116, .65) 0%, rgba(60, 0, 60, .8) 100%)`,
    },
  },
  [ChallengeSubtype.NoBunkers]: {
    title: 'No bunkers',
    Icon: StarIcon,
    chipColor: 'green.light',
    backgroundImage: NoBunkersBackground,
    style: {
      fontFamily: RubikGlitchFont,
      background: 'linear-gradient(108.21deg, rgba(26, 164, 11, .65) 0%, rgba(14, 89, 6, .8) 100%)',
    },
  },
  [ChallengeSubtype.NoRough]: {
    title: 'No rough',
    Icon: DiamondVioletIcon,
    chipColor: 'purple.light',
    backgroundImage: NoRoughBackground,
    style: {
      fontFamily: RubikDoodleShadowFont,
      background:
        'linear-gradient(108.21deg, rgba(109, 52, 167, .65) 0%, rgba(167, 52, 135, .8) 100%)',
    },
  },
  [ChallengeSubtype.NoWater]: {
    Icon: DiamondBlueIcon,
    title: 'No Water',
    chipColor: 'blue.dark',
    backgroundImage: NoWaterBackground,
    style: {
      fontFamily: RebellionSquadFont,
      background: `linear-gradient(108.21deg, rgba(52, 81, 167, .65) 0%, rgba(0, 26, 119, .8) 100%)`,
    },
  },
} as const satisfies Record<ChallengeSubtype, ChallengeRow>;
