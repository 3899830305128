import { Stack, styled } from '@mui/material';

import backgroundImage from 'targets/web/assets/images/background.png';

export const PageLayout = styled(Stack)(({ theme }) => ({
  backgroundImage: `url(${backgroundImage})`,
  backgroundColor: theme.palette.opacity[80],
  backgroundBlendMode: 'overlay',
  backgroundSize: 'cover',

  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '100vh',
  padding: theme.spacing(10),
  gap: theme.spacing(6),
}));
