import { Button, Stack } from '@mui/material';
import {
  createGameEvent,
  GameCourseLevel,
  GameEventType,
} from 'common/infrastructure/modules/game';

import { client } from 'common-frontend/infrastructure/modules/game/hooks/client';

type Stage = 'waiting-room' | 'contest-in-progress-waiting' | 'player-joined' | 'win' | 'lose';

const getPayloadForType = (type: Stage) => {
  switch (type) {
    case 'contest-in-progress-waiting':
      return {
        type: GameEventType.HOLE_CHANGED,
      };
    case 'player-joined':
      return {
        type: GameEventType.PLAYER_JOINED,
      };
    case 'win':
    case 'lose':
      return {
        type: GameEventType.END,
        content: JSON.stringify({
          wager: 100,
          challengesWon: type === 'win' ? 3 : 0,
          newBalance: 1000,
        }),
      };
    case 'waiting-room':
    default:
      return {
        type: GameEventType.START,
      };
  }
};

export const TestButtonsSection = ({
  locationId,
  bayId,
  isOpen,
}: {
  locationId: string;
  bayId: string;
  isOpen?: boolean;
}) => {
  const handleClick = (type: Stage) => {
    const commonPayload = {
      bay: bayId,
      location: locationId,
      players: [
        {
          id: 'test-player-id',
          name: 'Test Player',
          challengesWon: 1,
          active: true,
          wager: 100,
        },
      ],
      timestamp: new Date().toJSON(),
      content: JSON.stringify(null),
      hole: 1,
      gameCourseId: 'test-course-id',
      gameCourseLevel: GameCourseLevel.BEGINNER,
    };

    client.graphql({
      query: createGameEvent,
      variables: {
        input: {
          ...commonPayload,
          ...getPayloadForType(type),
        },
      },
    });
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Stack
      sx={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        borderRadius: 1,
        backgroundColor: 'background.paper',
        padding: 2,
        gap: 2,
        justifyContent: 'end',
      }}>
      <Button variant="contained" onClick={() => handleClick('waiting-room')}>
        Waiting Room
      </Button>

      <Button variant="contained" onClick={() => handleClick('contest-in-progress-waiting')}>
        Contest In Progress
      </Button>

      <Button variant="contained" onClick={() => handleClick('player-joined')}>
        Contest In Progress (Player Joined)
      </Button>

      <Button variant="contained" onClick={() => handleClick('win')}>
        Results (Win)
      </Button>

      <Button variant="contained" onClick={() => handleClick('lose')}>
        Results (Lose)
      </Button>
    </Stack>
  );
};
